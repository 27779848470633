import React from 'react'
import { Link } from 'gatsby'
import { graphql, useStaticQuery } from "gatsby"
import windowsImg from '../images/downloadWindows.png'
import appleImg from '../images/downloadApple.png'
import androidImg from '../images/downloadAndroid.png'

export const query = graphql`
  {
    software: allStrapiSoftware {
      nodes {
        products {
          model
        }
        platforms
        file {
          localFile {
            publicURL
          }
        }
        version
        title
        releaseDate
      }
    }
  }
`

export default function SupportSoftwares() {
    const data = useStaticQuery(query)
    const software = data.software.nodes

    return (
        <div>
            {software.map(software => {
                return (
                    <div className='pb-8'>
                        <h3 className=" leading-none text-lg md:text-xl text-primary pb-8">{software.products[0].model}</h3>
                        <div className='grid grid-cols-1 md:grid-cols-2 md:border-r border-secondary gap-x-16 gap-y-4 w-full'>
                            {(software.platforms == "Android" || software.platforms == "Apple") &&
                                <div className=" border-secondary w-full">
                                    <span className="text-lg md:text-xl font-bold">For Mobile</span>
                                    <div className="flex gap-6 md:gap-8 mt-2 text-xs md:text-base">

                                        <a download target="_blank" href={software.file.localFile.publicURL}>
                                            {software.platforms == "Apple" ?
                                                <img src={appleImg} alt="Apple Software" className="h-10 md:h-12 2xl:h-16 mb-1" />
                                                :
                                                <img src={androidImg} alt="Android Software" className="h-10 md:h-12 2xl:h-16 mb-1" />
                                            }
                                            <span>{software.title}</span> <br />
                                            <span>Ver: {software.version} | Release: {software.releaseDate}</span>
                                        </a>
                                    </div>
                                </div>
                            }
                            {software.platforms == "Windows" &&
                                <div>
                                    <span className="text-lg md:text-xl font-bold">For Desktop</span>
                                    <div className="mt-2 text-xs md:text-base">
                                        <a  download target="_blank" href={software.file.localFile.publicURL}>
                                            <img
                                                src={windowsImg}
                                                alt="Windows Software"
                                                className="h-10 md:h-12 2xl:h-16 mb-1"
                                            />
                                            <span>{software.title}</span> <br />
                                            <span>Ver: {software.version} | Release: {software.releaseDate}</span>
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
