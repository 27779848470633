import React from 'react'
import catalogImg from '../images/catalog.png'
import { RiDownload2Fill } from '@react-icons/all-files/ri/RiDownload2Fill'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby'

export const query = graphql`
  {
    catalogs: allStrapiCatalog {
      nodes {
        Name
        file {
          localFile {
            publicURL
          }
        }
        thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`


export default function SupportCatalogs() {
    const data = useStaticQuery(query)
    const catalogs = data.catalogs.nodes

    return (
        <div className="grid grid-cols-2 md:grid-cols-5 2xl:grid-cols-6 gap-10">
            {catalogs.map(catalog => {
                return (
                    <div className='w-full text-center'>
                        <GatsbyImage 
                          image={getImage(catalog.thumbnail.localFile)}
                          className="w-full border border-secondary"
                          alt={catalog.Name}
                        />
                        <span className='font-medium text-base w-full'>{catalog.Name}</span>
                        <a href={catalog.file.localFile.publicURL} target="_blank"
                            className='mt-6 flex items-center gap-2 justify-center mx-auto hover:text-primary hover:bg-transparent border hover:border-primary w-full text-lg  rounded bg-primary text-white transition-all duration-150 py-2'
                        >
                            Download
                            <RiDownload2Fill />
                        </a>
                        
                    </div>
                )
            })}
        </div>
    )
}
